<template>
	<div class="flex-col page">
		<div class="wxtop">
			<h3>{{ title }}</h3>
			<div class="white">
				<div class="cont">
					<div class="row-box">
						<div class="wxleft">选择楼盘</div>
						<div class="wxright">
							<van-dropdown-menu active-color="#f60" color="#f60">
								<van-dropdown-item
									v-model="houses_id"
									:options="houseslist"
									@change="change_houses()"
								/>
							</van-dropdown-menu>
						</div>
						<div class="clear"></div>
					</div>
					<div class="row-box">
						<div class="wxleft">选择电梯</div>
						<div class="wxright">
							<van-dropdown-menu active-color="#f60" color="#f60">
								<van-dropdown-item
									v-model="elevator_id"
									:options="elevatorlist"
								/>
							</van-dropdown-menu>
						</div>
						<div class="clear"></div>
					</div>
					<div class="row-box">
						<div class="wxleft">故障类型</div>
						<div class="wxright">
							<van-dropdown-menu active-color="#f60" color="#f60">
								<van-dropdown-item v-model="fault_id" :options="faultlist" />
							</van-dropdown-menu>
						</div>
						<div class="clear"></div>
					</div>

					<div class="row-box">
						<div class="wxleft">报修人电话</div>
						<div class="wxright">
							<van-field
								active-color="#f60"
								color="#f60"
								v-model="PersonPhone"
								placeholder="报修人电话"
								:rules="[{ required: true, message: '请填写报修人电话' }]"
								input-align="center"
							/>
						</div>
						<div class="clear"></div>
					</div>

					<div class="tit">
						<div class="wxleft">故障详情</div>
						<textarea
							id="textarea"
							v-model="EventDesc"
							placeholder="请输入维修说明"
							cols="45"
							rows="5"
						></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="button flex-col items-center text-wrapper_1" @click="confirm()">
			<span>发布工单</span>
		</div>
	</div>
</template>

<script>
import {
	selectHouses,
	selectElevator,
	selectFault,
	addAlarm,
} from "@/api/Property/Order/all";
import { Toast } from "vant";

export default {
	data() {
		return {
			title: "发布维修工单",
			houses_id: 0,
			elevator_id: 0,
			fault_id: 0,
			EventDesc: "",
			houseslist: [{ text: "请选择楼盘", value: 0 }],
			elevatorlist: [{ text: "请选择电梯", value: 0 }],
			faultlist: [{ text: "请选择故障类型", value: 0 }],
			PersonPhone: "",
		};
	},
	created() {
		selectHouses().then((res) => {
			if (res.data.Result === 200) {
				res.data.Data.forEach((item, i) => {
					let obj = {};
					obj.text = item.HousesName;
					obj.value = item.id;
					this.houseslist.push(obj);
				});
			}
		});
		selectFault().then((res) => {
			if (res.data.Result === 200) {
				res.data.Data.forEach((item, i) => {
					let obj = {};
					obj.text = item.text;
					obj.value = item.id;
					this.faultlist.push(obj);
				});
			}
		});
	},
	mounted() {},
	methods: {
		//切换楼盘
		change_houses() {
			this.elevatorlist = []; //{text:'请选择电梯',value:0}
			let houses_id = this.houses_id; // 1级分类切换
			selectElevator({ houses_id: houses_id }).then((res) => {
				if (res.data.Result === 200) {
					res.data.Data.forEach((item, i) => {
						let obj = {};
						obj.text = item.Number;
						obj.value = item.id;
						this.elevatorlist.push(obj);
					});
				}
			});
		},
		confirm() {
			let data = {
				// houses_id  : this.houses_id,   //楼盘id
				elevator_id: this.elevator_id, //电梯id
				fault_id: this.fault_id, //故障id
				PersonPhone: this.PersonPhone,
				EventDesc: this.EventDesc,
			};
			addAlarm(data).then((res) => {
				console.log(res);
				if (res.data.Result === 200) {
					Toast("发布维修工单成功");
					// 跳到工单详情
					this.$router.replace("/property/index");
				} else {
					this.$router.replace("/property/index");
					Toast("状态异常,暂时无法处理");
				}
			});
		},
	},
};
</script>

<style scoped>
@import "../../assets/common.css";
.clear {
	clear: both;
}
.button {
	color: rgb(255, 255, 255);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	padding: 1.06rem 0;
	background-image: url("../../assets/buttonbg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.text-wrapper_1 {
	margin-top: 33.88rem;
}
.wxtop {
	background: #0057c8;
	height: 14rem;
	position: relative;
}
.wxtop h3 {
	color: #ffffff;
	margin-top: 4rem;
	margin-left: 2.5rem;
	font-size: 1.7rem;
	font-weight: normal;
}
.white {
	padding-top: 2rem;
	width: 100%;
	position: absolute;
	top: 11.5rem;
	background: #ffffff;
	border-radius: 1.7rem 1.7rem 0 0;
}
.cont {
	width: 100%;
	padding: 0 1.5rem;
	overflow: hidden;
}

.wxleft {
	margin-top: 6%;
	width: 20%;
	float: left;
	color: #b3b3b2;
}
.wxright {
	width: 75%;
	float: right;
	margin-top: 3%;
	overflow: hidden;
	border-radius: 0.8rem;
}
.tit .wxleft {
	font-size: 1.4rem;
	width: 30%;
	color: black;
	float: none;
	margin-bottom: 1rem;
}
#textarea {
	display: block;
	margin: 0 auto;
	resize: none;
	background: #f8f9fd;
	border: none;
}
</style>
<style>
.van-dropdown-menu__item {
	background: #f8f9fd;
}
.van-dropdown-menu__title {
	color: #b3b3b2;
}

.van-field {
	background: #f8f9fd;
}
.van-field__control--center {
	color: #b3b3b2 !important;
}
</style>
